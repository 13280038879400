<template>
  <div class="home">
    <q-page class="flex flex-center row">
        <q-card class="col-xl-6 col-sm-8">
            <q-card-section class="bg-primary text-white">
                <div class="text-h6">Cotisation</div>
            </q-card-section>
            <q-card-section class="no-padding">
                <p class="bg-green-1 q-pa-sm text-h6 no-margin" v-if="user.is_subscription_valid">
                    <q-icon name="info" color="primary" style="font-size: 2rem;" />
                    Votre cotisation est à jour !
                </p>
                <q-stepper v-else v-model="step" ref="stepper" color="primary" animated flat bordered>
                    <q-step :name="1" title="Informations" :done="step > 1" icon="info">
                        <p class="bg-red-1 q-pa-sm">
                            <q-icon name="error" class="text-red" style="font-size: 1.5rem;" />
                            Votre cotisation n'est pas à jour !
                        </p>
                        <h5 class="no-margin">Pourquoi cotiser ?</h5>
                        <p>
                            Cotiser permet d’adhérer à l’association. En plus de vous donner une voix dans les décisions et votes de l’assemblée générale, cela fait vivre l’association.
                        </p>
                        <h5 class="no-margin">A quoi sert l'argent de ma cotisation ?</h5>
                        <p>
                            La cotisation sert principalement au financement des frais fixes de l'association (banque, nom de domaine, adresse e-mail). <br>
                            Une fois ces frais financés, le résultat restant pourra être utilisé pour l'achat d'équipements (voté en assemblée générale) ou participer au budget des IRL.
                        </p>
                        <h5 class="no-margin">A quelle fréquence dois-je la payer ?</h5>
                        <p>La cotisation est à payer chaque année. Celle-ci doit être réglée avant le dernier jour de Février de l'année en cours.</p>
                    </q-step>
                    <q-step :name="2" title="Coordonnées" :done="step > 2" icon="contact_page">
                        <q-form>
                            <q-input v-model="user.first_name" type="text" label="Prénom" :rules="[val => !!val || 'Ce champ est obligatoire']"/>
                            <q-input v-model="user.last_name" type="text" label="Nom" :rules="[val => !!val || 'Ce champ est obligatoire']"/>
                            <q-input v-model="user.email" type="email" label="Adresse email" :rules="[val => !!val || 'Ce champ est obligatoire']"/>
                        </q-form>
                    </q-step>
                    <q-step :name="3" title="Choix Montant" :done="step > 3" icon="functions">
                        <p>
                            Le montant minimum à payer pour la cotisation est de 5 € minimum. <br>
                            Les frais de transaction sont à la charge de l'adhérent.
                        </p>
                        <q-input v-model="amount" type="number" label="Montant" :rules="[val => val >= 5 || 'Le montant minimum est de 5€']">
                            <template v-slot:append>
                                <q-icon name="euro" />
                            </template>
                        </q-input>
                        <div><em>Frais de transaction : {{ fees }} €</em></div>
                        <div>Total à régler : <strong> {{ total_amount }} </strong> € </div>
                    </q-step>
                    <q-step :name="4" title="Paiement" :done="step > 3" icon="payment">
                        <div> Montant débité : <q-badge color="primary">{{total_amount}} €</q-badge></div>
                        <StripePayment :amount="total_amount" :description="payment_description" class="q-mt-md" ref="stripe" />
                    </q-step>
                    <template v-slot:navigation>
                        <q-separator spaced />
                        <q-stepper-navigation class="text-right">
                            <q-btn v-if="step > 1" flat color="primary" @click="$refs.stepper.previous()" label="Précédent" class="q-ml-sm" />
                            <q-btn @click="nextStep" color="primary" v-if="step == 1" label="Suivant" />
                            <q-btn @click="nextStep" color="primary" v-if="step == 2" :disabled="!(user.first_name && user.last_name && user.email)" label="Suivant" />
                            <q-btn @click="nextStep" color="primary" v-if="step == 3" :disabled="amount < 5" label="Suivant" />
                            <q-btn @click="pay" color="primary" v-if="step == 4" label="Payer" />
                        </q-stepper-navigation>
                    </template>
                </q-stepper>
                <q-inner-loading :showing="is_loading">
                    <q-spinner-gears size="50px" color="primary" />
                </q-inner-loading>
            </q-card-section>
        </q-card>
    </q-page>
  </div>
</template>

<script>
import { mapState } from "vuex";
import StripePayment from "@/components/StripePayment";
import Api from "@/utils/api.js";

export default {
    name: 'Subscription',
    components: {
        StripePayment
    },
    computed: {
        fees () {
            return (this.total_amount - this.amount).toFixed(2);
        },
        total_amount () {
            return (this.amount / 0.986 + 0.25).toFixed(2);
        },
        subscription_valid () {
            return this.user.__data.subscription_year == this.year;
        },
        ...mapState(["user"])
    },
    data () {
        return {
            step: 1,
            is_loading: false,
            amount: 5,
            payment_description: `Cotisation Mandragore ${new Date().getFullYear()}`,
            year: new Date().getFullYear()
        };
    },
    methods: {
        async nextStep() {
            try {
                if (this.step === 2) {
                    this.is_loading = true;
                    await this.user.update();
                    this.is_loading = false;
                }

                this.$refs.stepper.next();
            } catch (e) {
                this.is_loading = false;
            }
        },
        async pay () {
            this.is_loading = true;
            try {
                let { payment } = await this.$refs.stripe.pay();
                await Api.post({
                    handler: "user.pay_subscription",
                    args: {
                        payment: payment
                    }
                });
                this.$q.notify({
                    color: "primary",
                    message: "Félicitations ! Votre cotisation a bien été payée pour cette année !"
                });
            } catch (e) {
                console.error(e);
            } finally {
                this.is_loading = false;
            }
        }
    }
}
</script>
